/*
--------------------------------------------------------------
table of contents
1. 
_______________________________________________________________
*/

:root {
  --primary-bg: #fff;
  --primary-color: #333;
  --seconday-bg: #333;
  --secondary-color: #fff;
  --card-bg: #e3f5f3;
  --btn-bg: #9c8501;
  --login-btn: #02551e;
  --btn-hover: #052b26;
}

* {
  box-sizing: border-box;
}

a {
  color: #0092bb;
  text-decoration: none;
}
ul li {
  list-style-type: none;
}
img {
  width: 100%;
  height: 100%;
  position: relative;
}

button {
  border-radius: 5px;
  color: var(--primary-bg);
  background-color: var(--btn-bg);
}

button:hover {
  color: var(--primary-bg);
  background-color: var(--btn-hover);
}
footer {
  bottom: 0;
  margin: 0;
  width: 100%;
  height: 100px;
  z-index: 100;
  padding: 2% 6%;
  display: grid;
  position: static;
  place-items: center;
}
footer p {
  margin: 0;
}
.social {
  left: -15px;
  top: 200px;
  display: block;
  position: absolute;
}
.social li {
  margin: 1rem 0;
}
.socialColor a {
  color: var(--secondary-color);
}
.Navbar {
  top: 0;
  margin: 0;
  width: 100%;
  height: 60px;
  z-index: 100;
  padding: 0 6%;
  display: flex;
  position: fixed;
  border: 1px solid;
  align-items: center;
  justify-content: space-between;
}
.userAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 5px;
}
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 0;
}

.Navbar nav {
  width: 60%;
  height: 100%;
  display: flex;
  max-width: 400px;
  align-items: center;
}

.Navbar nav ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.lightTheme {
  background-color: var(--primary-bg);
  color: var(--primary-color);
  animation: App-logo-spin;
}

.darkTheme {
  background-color: var(--seconday-bg);
  color: var(--secondary-color);
  animation: App-logo-spin;
}

.mainWraper {
  width: 100%;
  margin: 0;
  padding: 0;
  display: grid;
  margin-top: 62px;
  place-items: center;
  min-height: 520px;
}
.error {
  max-width: 100%;
  padding: 2px;
  display: grid;
  color: #fff;
  border-radius: 5px;
  background-color: #7e1214;
  place-items: center;
}

/* 
--------------------------------------------------------------------
Home Page
------------------------------------------------------------------*/
section {
  width: 80%;
  display: grid;
  font-size: larger;
  place-items: center;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}
.separator {
  width: 80%;
  margin-bottom: 2rem;
  border-bottom: 4px solid;
  background-color: #9c8501;
}

.card {
  width: 90%;
  display: grid;
  min-width: 170px;
  border-radius: 5px;
  padding-bottom: 1rem;
  place-items: center;
  text-align: justify;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.22);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.22);
}

/* Intro section */
.intro {
  font-family: Arial, Helvetica, sans-serif;
  background-image: url('./components/Assets/image/izuking_logo.jpeg');
  height: auto;
  min-height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: soft-light;
  background-size: cover;
  /* position: relative; */
  border-bottom: 4px solid;
  margin-bottom: 2rem;
  place-items: center;
  font-size: larger;
  display: grid;
  width: 80%;
}
.intro h1 h3 p {
  margin: 0;
  width: 100%;
  display: grid;
  place-items: center;
}

.intro h1 {
  top: 30px;
  font-size: 55pt;
  position: relative;
}
.intro h3 {
  top: -60px;
  text-align: center;
  position: relative;
}
.intro p {
  top: -100px;
  width: 80%;
  /* font-family: 'Courier New', Courier, monospace; */
  font-style: italic;
  position: relative;
  text-align: justify;
  margin-bottom: 2rem;
}

/* -------------------------------------------
  Course section 
---------------------------------------------*/
.courseWrap {
  gap: 2rem;
  width: 100%;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(5, 1fr);
}

.descript {
  font-size: 12pt;
  font-style: italic;
  width: 100%;
  position: relative;
  top: -20px;
  text-align: center;
  margin: 0 0 1.5rem;
}
.card h3 {
  margin: 0.5rem 0 0;
}
.coursework {
  margin: 0.5rem 0 0;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid;
}
.card ul {
  width: 100%;
  padding: 0 5px;
  margin: 0.5rem 0 1rem;
}
.card li {
  width: 100%;
  margin: 2px 0;
  display: grid;
  place-items: center;
  border-bottom: 1px solid;
}

.Link:hover {
  color: #f3bd0b;
  text-transform: capitalize;
}

/* -------------------------------------------
 Project  section 
---------------------------------------------*/
.projectWrap {
  gap: 2rem;
  width: 100%;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
}

.projectImgWrap {
  width: 100%;
  height: 200px;
  border-radius: 5px 5px 0 0;
  border-bottom: 10px solid var(--btn-bg);
}
.projectImgWrap img {
  border-radius: 5px 5px 0 0;
}
.projectTextWrap {
  position: relative;
  display: grid;
  top: -32px;
  left: 0;
  width: 100%;
  height: 300px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  margin-bottom: -32px;
  padding: 0 1rem;
  place-items: center;
}
.preview {
  top: -40px;
  width: 70px;
  height: 70px;
  display: grid;
  font-weight: bold;
  border-radius: 50%;
  position: relative;
  place-items: center;
  background-color: #a0a0a0;
  border: 8px solid var(--btn-bg);
}
.preview:hover {
  border: 8px solid #1111be;
}
.projectWrap h3,
.projectWrap p,
.projectWrap span {
  margin: 0;
}

.projectWrap span {
  font-style: italic;
  font-size: smaller;
  font-family: monospace;
  text-transform: capitalize;
}
.morePro {
  width: 100%;
  display: grid;
  place-content: end;
  margin: 2rem 0 0;
  padding-right: 1.5rem;
}

.card .links {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
  padding: 0 15px;
}
/* -------------------------------------------
  Comment section 
---------------------------------------------*/
.commentsWrap {
  gap: 2rem;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
}
.heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.profile {
  display: flex;
  margin-top: 5px;
}
.commentAvatar {
  display: grid;
  place-items: center;
  width: 25px;
  height: 30px;
  margin: 0;
}
.profileName {
  margin: 0 5px 0 7px;
}
.profileSay {
  color: gray;
  font-size: 13pt;
}
.createdAt,
.profileReply {
  font-style: italic;
  font-size: 10pt;
  color: gray;
  margin: 0;
  width: 80px;
}
.profileReply {
  margin-right: -10%;
  margin-bottom: 2rem;
}

.message {
  height: auto;
  font-size: 12pt;
}

.heading,
.message {
  width: 90%;
  margin-top: 0.5rem;
}
.replyFormWrap {
  width: 100%;
  margin-top: 1rem;
  margin-left: 2.5rem;
}

.replyForm {
  width: 100%;
  height: 40px;
  max-width: 500px;
  overflow: hidden;
}
.replyFormX {
  width: 100%;
  height: auto;
  max-width: 500px;
}

.replyForm input,
.replyFormX input {
  width: 95%;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.replyForm textarea,
.replyFormX textarea {
  width: 95%;
  height: 100px;
  margin-bottom: 1rem;
  border-radius: 5px;
  resize: inherit;
}
.replyFormX button {
  width: 95%;
}

/* -------------------------------------------------------------------
  Sign Up page
--------------------------------------------------------------------*/

.signup {
  width: 75%;
  height: auto;
  display: grid;
  max-width: 600px;
  margin: 3rem auto;
  border-radius: 5px;
  place-items: center;
  padding-bottom: 2.5rem;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.54);
  -webkit-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.54);
  -moz-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.54);
}
.imgWrapper {
  width: 100px;
  height: 80px;
  display: grid;
  margin: 2rem auto;
  place-items: center;
}

.inputWrapper {
  width: 90%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.inputWrapper input {
  padding: auto;
  border-bottom: 2px solid;
}

.inputWrapper input,
.genderWrap {
  width: 90%;
  height: 40px;
  border: 1px solid;
  border-radius: 5px;
  margin: 0.3rem 1rem;
  border-bottom: 2px solid;
}
.togleSignupPassword {
  display: grid;
  position: relative;
  top: -33px;
  left: 83%;
}
.courseWrap {
  position: relative;
  top: -15px;
}
.genderWrap {
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selectWrapper {
  width: 100%;
  height: 60px;
  display: flex;
  padding: 0 5px;
  margin: 1rem auto;
  justify-content: space-around;
}
.selectWrapper select {
  width: 100px;
}

.btnWrapper {
  width: 90%;
  height: auto;
  display: grid;
  place-items: center;
  align-items: center;
  margin-bottom: 2rem;
}
.btnWrapper button {
  width: 95%;
  margin: 0.5rem;
  padding: 5px 0;
  border-radius: 5px;
  font-weight: bolder;
  color: var(--primary-bg);
  background-color: var(--login-btn);
}
.btnWrapper button:hover {
  color: var(--primary-bg);
  background-color: var(--btn-hover);
}
.btnWrapper .google {
  background-color: #7e1214;
  width: 95%;
}
.btnWrapper .google:hover {
  background-color: #f76568;
}

/*-------------------------------------------------------------------- 
LogIn Up Page
-------------------------------------------------------------------- */

.login {
  width: 80%;
  display: grid;
  max-width: 500px;
  margin: 3rem auto;
  border-radius: 5px;
  padding-bottom: 3rem;
  place-items: center;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.54);
  -webkit-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.54);
  -moz-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.54);
}
.login input,
.login button,
.login .google {
  width: 70%;
  border-radius: 5px;
}

.login input {
  height: 40px;
  margin: 1rem 0;
  padding: auto;
  border-bottom: 1px solid black;
}
.toglePassword {
  display: grid;
  position: relative;
  top: -40px;
  left: 30%;
}
.login button {
  padding: 5px 0;
  font-weight: bolder;
  margin: 1rem 0 0.5rem;
  color: var(--primary-bg);
  background-color: var(--login-btn);
}
.login button:hover {
  background-color: var(--btn-hover);
}
.login .google {
  background-color: #7e1214;
}
.login .google:hover {
  background-color: #f76568;
}
/* -----------------------------------------------------------------------
User Page
------------------------------------------------------------------------ */
.userShowCase {
  width: 80%;
  height: auto;
  display: grid;
  place-items: center;
  text-align: justify;
  border-bottom: 2px solid;
  padding: 15px 0px;
}

.userShowCase h1 {
  font-family: monospace;
  margin: 0 0 5px;
}
.userShowCase h3,
.userShowCase p {
  margin: 0;
  font-family: 'Courier New', Courier, monospace;
}

.userCourseWrap {
  gap: 1rem;
  width: 80%;
  height: auto;
  display: grid;
  padding: 1rem 1rem 2rem;
  grid-template-columns: repeat(3, 1fr);
}

.userCourseWrap .card h3 {
  margin: 0.7rem 0 0;
}
.userCourseWrap .card ol {
  width: 100%;
  padding: 1px;
  margin: 0;
  list-style-type: decimal-leading-zero;
}
.userCourseWrap .card li {
  width: 160px;
  margin: 5px 0px;
  display: grid;
  border: 1px solid;
  border-radius: 25px;
}
.modal {
  width: 70%;
  min-height: 600px;
  padding: 0 1rem 2rem;
  text-align: justify;
}
.back {
  position: absolute;
  left: 18px;
}
.modal h1 {
  text-align: center;
  padding-bottom: 1rem;
  border-bottom: 3px solid;
}
.modal ol {
  text-align: justify;
  margin: 0;
  padding-left: 1rem;
  padding-bottom: 1rem;
}
/* -------------------------------------------------------------------------------
  AdminPage
---------------------------------------------------------------------------------*/
.studentModal {
  top: 50%;
  right: 20%;
  width: 50%;
  z-index: 100;
  display: grid;
  margin: 0 auto;
  color: black;
  position: fixed;
  min-width: 400px;
  border: 1px solid;
  border-radius: 5px;
  place-self: center;
  place-items: center;
  background-color: #fff;
}
.studentWrap {
  gap: 2%;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.editbtn {
  gap: 2%;
  width: 90%;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
}
.editbtn button {
  width: 90%;
  margin: 1rem;
}

.studentWrap ol {
  margin: 0;
  padding: 0;
}
.studentWrap li {
  margin: 1rem 0;
  padding: 0;
}
.studentProfileWrap {
  padding: 5px 0;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.studentProfile {
  width: 90%;
  display: flex;
  place-items: center;
}

.studentName {
  margin-left: 5%;
  width: auto;
}
.studentName h4,
.studentName p {
  margin: 0;
}

/* -------------------------------------------------------------------------------
  Page Not Found
---------------------------------------------------------------------------------*/
.pageWrap {
  width: 80%;
  display: grid;
  max-width: 500px;
  grid-template-columns: repeat(2, 1fr);
}
.imgWrap {
  width: 200px;
  height: 300px;
}
.textWrap {
  font-family: Arial, Helvetica, sans-serif;
  height: auto;
  min-height: 100px;
  place-items: center;
  text-align: justify;
  display: grid;
  width: 140px;
}
/* -------------------------------------------------------------------------------
  MediaQuary Mini-laptop
---------------------------------------------------------------------------------*/
@media only screen and (max-width: 1130px) {
  .courseWrap {
    grid-template-columns: repeat(4, 1fr);
  }

  .projectWrap {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 880px) {
  section,
  .separator {
    margin-left: 3rem;
  }
  .card {
    width: 90%;
  }
  .courseWrap {
    grid-template-columns: repeat(3, 1fr);
  }
  .commentsWrap {
    width: 100%;
    grid-template-columns: 1fr;
  }
  .userCourseWrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
/* -------------------------------------------------------------------------------
  MediaQuary Tablet and below
---------------------------------------------------------------------------------*/
@media only screen and (max-width: 730px) {
  .intro {
    width: 75%;
  }
  .intro p {
    top: -60px;
  }
  .replyFormWrap {
    margin-left: 0;
  }
  section,
  .separator {
    margin-left: 2rem;
    width: 70%;
  }

  .card,
  .editbtn button {
    width: 100%;
  }
  .selectWrapper select {
    width: 80px;
  }
  .selectWrapper,
  .btnWrapper button,
  .btnWrapper .google {
    width: 90%;
  }

  .courseWrap {
    grid-template-columns: repeat(1, 1fr);
  }
  .projectWrap,
  .commentsWrap {
    width: 100%;
    grid-template-columns: 1fr;
  }
  .userCourseWrap,
  .inputWrapper,
  .studentWrap,
  .editbtn {
    grid-template-columns: 1fr;
  }
  .Navbar nav {
    width: 70%;
  }
  .themeBtn {
    display: none;
  }

  .studentModal {
    top: 40%;
    right: 1%;
  }
}
